<template>
    <div id="properties">
        <hr class="menu-separator">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div v-else class="title-filter-container">
            <div class="page-title">
                <h2>
                    Properties
                </h2>
            </div>
            <div class="filters">
                <multiselect @input="updateFilterOption" :value="currentFilterOption" placeholder="Select status" :showLabels="false" :options="options" track-by="label" label="label" :searchable="false" :allow-empty="false">
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
                </multiselect>
            </div>
        </div>
        <div v-if="!loading" class="featured-properties">
            <FeaturedProperties v-if="filteredProperties.length > 0" align="right" title="Exclusive Listings" :data="filteredProperties"/>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Spinner from 'vue-simple-spinner';
import FeaturedProperties from "../components/FeaturedProperties"
import { mapState, mapActions } from 'vuex';

export default {
    name: "Properties",
    components: {
        Spinner,
        Multiselect,
        FeaturedProperties
    },
    data(){
        return{
            title: "Properties",
            url: 'https://strapi.uptowncreative.io',
            options: [
                {label: 'All Properties', value: 'all'},
                {label: 'Available', value: 'available'},
                {label: 'Lease', value: 'lease'},
                {label: 'Sold', value: 'sold'},
            ],
            currentOption:  {label: 'ALL', value: 'all'},
        }
    },
    computed: {
        filteredProperties:function(){
            const unfilteredProperties = this.$store.getters.properties;
            if (this.$store.getters.currentFilterOption.value === 'all'){
                return unfilteredProperties.sort(
                    (a, b) => { 
                        if(a.Status !== 'sold' && b.Status === 'sold'){
                            return -1
                        }else if(a.Status === 'sold' && b.Status !== 'sold'){
                            return 1
                        }else if(b.Status === 'sold' && a.Status === 'sold') {
                            return b.Price - a.Price;
                        } else {
                            return 1;
                        }
                    })
            } else if (this.$store.getters.currentFilterOption.value === 'sold'){
                return unfilteredProperties.filter((item) => {
                    return item.Status === 'sold' || item.Status === 'past';
                }).sort((a,b) => {
                    return b.Price - a.Price;
                })
            } else {
                return unfilteredProperties.filter((item) => {
                    return item.Status === this.$store.getters.currentFilterOption.value;
                })
            }
        },
        loading: function(){
            return !this.$store.getters.getLoaded;
        },
        ...mapState(['currentFilterOption'])
    },
    metaInfo: {
        title: 'Properties',
        titleTemplate: '%s | Coastal Luxe',
        meta: [{
            name: 'description', 
            content: "Coastal Luxe"
        }]
    },
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        preloadFilter(){
            if (this.$route.params.category){
                switch (this.$route.params.category){
                    case 'forSale':
                        this.$store.dispatch('updateFilterOption',this.options[1]);
                        break;
                    case 'forLease':
                        this.$store.dispatch('updateFilterOption',this.options[2]);
                        break;
                    case 'sold':
                        this.$store.dispatch('updateFilterOption',this.options[3]);
                        break;
                    default:
                        this.$store.dispatch('updateFilterOption',this.options[1]);
                        break;  
                }
            }
        },
        ...mapActions(['updateFilterOption'])
    },
    created() {
       this.preloadFilter();
    },
    mounted:function(){
        if(!this.$store.getters.loaded){
            this.$store.dispatch('updateProperties');
            this.$store.dispatch('propertiesLoaded');
        } else if(this.$store.getters.checkExpiryDate){
            this.$store.dispatch('updateProperties');
        }
    },
    watch: {
        $route() {
            this.preloadFilter();
        }
    }

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.title-filter-container{
    display: flex;
    align-items: center;
    margin: 3rem auto;
    position: relative;
    .page-title{
        flex: 0 1 auto;
        position: absolute;
        top: -150%;
        left: 50%;
        transform: translateX(-50%);
    }
    .filters{
        flex: 0 1 auto;
        margin-left: auto;
        margin-right: 30px;
    }
}

.properties-container{
    margin: 0 auto;
    max-width: 1000px;
}
.property{
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-top: 4rem;
    &:last-child{
        border-bottom: none;
    }
    a{
        display: block;
    }
    img{
        width: 100%;
        max-height: 470px;
        display: block;
        object-fit: cover;
    }
    .info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .address{
        display: flex;
        flex-direction: column;
        color: #fff;
        h3{
            font-weight: 500;
            margin: 0;
            opacity: 0.5;
            text-transform: uppercase;
            &:hover{
                opacity: 1;
            }
        }
        p{
            text-transform: uppercase;
            font-weight: 100;
            &:hover{
                opacity: 1;
            }
        }
    }
    .Status{
        margin: 0;
        color: #fff;
    }
    .Status.sold, .Status.past{
        color: #000 !important;
        background: #fff;
    }
}


@media (max-width: $tablet) {
    .title-filter-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .page-title{
            flex: unset;
            position: relative;
            left: unset;
            transform: unset;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        .filters{
            flex: unset;
            margin: 0 auto;
        }
    }
}
</style>
