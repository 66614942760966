<template>
    <div class="featured-properties">
        <div
            v-for="(property, i) in data"
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
            :data-aos-delay="400*(i % animationSequence)"
            class="property"
            :key="i">
            <span v-if="property.Tag" class="tag">{{property.Tag | startCase}}</span>
            <router-link :to="`/property/${property.Slug}`">
                <img :src="url+ property.DisplayImage.formats.medium.url" :alt="property.Address">
            </router-link>
            <p>
                {{property.Address}} <br>
                {{Number(property.Price) | priceFormat}} <span v-if="property.Status === 'lease'">/mo.</span>
            </p>
        </div>
    </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "FeaturedProperties",
    // components: { VueperSlides, VueperSlide},
    props: ['align', 'bgColor', 'data'],
    data(){
        return{
            url: 'https://strapi.uptowncreative.io',
        }
    },
    computed:{
        animationSequence(){
            if(window.width < 641){
                return 1;
            }
            if(window.width < 1079){
                return 2
            }
            return 3;
        }
    }
}
</script>

<style lang="scss" scoped>
.featured-properties{
    display: flex;
    flex-wrap: wrap;
    .property{
        position: relative;
        width: 33.33%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
        img{
            display: block;
            width: 100%;
            min-height: 300px;
            height:25vw;
        }
        &:nth-child(3n+1){
            padding-right: 10px;
        }
        &:nth-child(3n+2){
            padding-left: 5px;
            padding-right: 5px;
        }
        &:nth-child(3n+0){
            padding-left: 10px;
        }
        p{
            font-weight: 600;
        }
    }
}

@media (max-width: $tablet) {
    .featured-properties{
        .property{
            width: 50%;
            img{
                min-height: 200px;
                height: 40vw;
            }
            &:nth-child(3n+1){
                padding-right: unset;
            }
            &:nth-child(3n+2){
                padding-left: unset;
                padding-right: unset;
            }
            &:nth-child(3n+0){
                padding-left: unset;
            }
            &:nth-child(odd){
                padding-right: 10px;
            }
            &:nth-child(even){
                padding-left: 10px;
            }
        }
    }
}
@media (max-width: $mobile) {
    .featured-properties{
        .property{
            width: 100%;
            &:nth-child(odd){
                padding-right: unset;
            }
            &:nth-child(even){
                padding-left: unset;
            }
            img{
                min-height: 100px;
                height: 80vw;
            }
        }
    }
}
</style>